@import '../../site/styles/settings/functions.scss';

$loading-speed: 1.5s;

.loading-spinner {
  display: block;
  width: toRem(60px);
  height: toRem(60px);
  fill: var(--color-white);

  &__blades {
    transform-origin: 49.7% 44.6%;
    animation: loading-rotation $loading-speed linear infinite;

    @keyframes loading-rotation {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}
