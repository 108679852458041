/**
 * Form button - Component
 */

.cmp-form-button--wrapper {
  margin-top: var(--spacing-lg);
}

@media #{$mq-1} {
  .cmp-form-button--wrapper {
    margin-top: var(--spacing-md);
  }
}
