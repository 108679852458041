@use 'sass:map';

:root {
  --grid-number-of-columns-12: 12;
  --grid-number-of-columns-8: 8;
  --grid-number-of-columns-4: 4;

  --page-container-side-margin-xs: 24px;
  --page-container-side-margin-sm: 30px;
  --page-container-side-margin-md: 30px;
  --page-container-side-margin-lg: 40px;
  --page-container-side-margin-xl: 120px;
  --page-container-side-margin-xxl: 160px;

  --gutter-width-xs: var(--spacing-sm);
  --gutter-width-sm: var(--spacing-sm);
  --gutter-width-md: var(--spacing-sm);
  --gutter-width-lg: var(--spacing-lg);
  --gutter-width-xl: var(--spacing-lg);
  --gutter-width-xxl: var(--spacing-xl);
}

@function get-column-width($breakpoint, $side-margin, $gutter-width, $number-columns: 12) {
  $number-of-gutters: calc(#{$number-columns} - 1);

  @return calc((#{$breakpoint} - (2 * #{$side-margin}) - (#{$number-of-gutters} * #{$gutter-width})) / #{$number-columns});
}

$grid-xs: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-xs), var(--spacing-sm), var(--grid-number-of-columns-4)),
  column-width-percentage: get-column-width(100%, var(--page-container-side-margin-xs), var(--spacing-sm), var(--grid-number-of-columns-4)),
  gutter-width: var(--spacing-sm),
  side-margins: var(--page-container-side-margin-xs),
);

$grid-sm: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-sm), var(--spacing-sm), var(--grid-number-of-columns-8)),
  column-width-percentage: get-column-width(100%, var(--page-container-side-margin-sm), var(--spacing-sm), var(--grid-number-of-columns-8)),
  gutter-width: var(--spacing-sm),
  side-margins: var(--page-container-side-margin-sm),
);

$grid-md: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-md), var(--spacing-sm), var(--grid-number-of-columns-12)),
  column-width-percentage: get-column-width(100%, var(--page-container-side-margin-md), var(--spacing-sm), var(--grid-number-of-columns-12)),
  gutter-width: var(--spacing-sm),
  side-margins: var(--page-container-side-margin-md),
);

$grid-lg: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-lg), var(--spacing-lg), var(--grid-number-of-columns-12)),
  column-width-percentage: get-column-width(100%, var(--page-container-side-margin-lg), var(--spacing-lg), var(--grid-number-of-columns-12)),
  gutter-width: var(--spacing-lg),
  side-margins: var(--page-container-side-margin-lg),
);

$grid-xl: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-xl), var(--spacing-lg), var(--grid-number-of-columns-12)),
  column-width-percentage: get-column-width(100%, var(--page-container-side-margin-xl), var(--spacing-lg), var(--grid-number-of-columns-12)),
  gutter-width: var(--spacing-lg),
  side-margins: var(--page-container-side-margin-xl),
);

$grid-xxl: (
  column-width: get-column-width(1921px, var(--page-container-side-margin-xxl), var(--spacing-xl), var(--grid-number-of-columns-12)),
  column-width-percentage: get-column-width(100%, var(--page-container-side-margin-xxl), var(--spacing-xl), var(--grid-number-of-columns-12)),
  gutter-width: var(--spacing-xl),
  side-margins: var(--page-container-side-margin-xxl),
);

$mq-to-grid: (
  1: $grid-xs,
  2: $grid-sm,
  3: $grid-md,
  4: $grid-lg,
  5: $grid-xl,
  6: $grid-xxl,
);

@function grid-spacing($mq: 0, $columns: 0, $gutters: 0, $side-margins: 0) {
  @if $mq > 0 and $mq < 7 {
    @return calc(
      #{$columns} * #{map.get(map.get($mq-to-grid, $mq), column-width)} + #{$gutters} * #{map.get(map.get($mq-to-grid, $mq), gutter-width)} + #{$side-margins} *
      #{map.get(map.get($mq-to-grid, $mq), side-margins)}
    );
  }

  @return 0;
}
