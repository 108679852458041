/**
 * Form options - Component
 */

.cmp-form-options {
  border: none;
  padding: 0;
}

.cmp-form-options--drop-down {
  min-height: 4.375rem; /* 70 */
}

.cmp-form-options__field--drop-down {
  display: none;
}

/* checkbox & radio */
.cmp-form-options__field-label {
  display: flex;
  order: 2;
  align-items: center;
}

.cmp-form-options__field--checkbox,
.cmp-form-options__field--radio {
  appearance: none;
  border: 0.125rem solid var(--color-gray-700);
  box-sizing: border-box;
  color: var(--color-gray-700);
  cursor: pointer;
  flex-shrink: 0;
  height: 1.125rem; /* 18 */
  margin-right: var(--spacing-sm);
  position: relative;
  vertical-align: -0.3125rem;
  width: 1.125rem; /* 18 */
}

.cmp-form-options__field--checkbox:disabled,
.cmp-form-options__field--radio:disabled {
  cursor: default;
}

.cmp-form-options__field--checkbox::before {
  @include bold-md();

  font-family: 'custom_materialicons', sans-serif;
  content: var(--icon-check);
  position: absolute;
  top: calc(var(--spacing-xxxs) * -1);
  visibility: hidden;
}

.cmp-form-options__field--checkbox:checked::before {
  visibility: visible;
}

.cmp-form-options__field--radio {
  border-radius: 50%;
}

.cmp-form-options__field--radio:checked::before {
  position: absolute;
  left: 0.125rem;
  top: 0.125rem;
  content: '';
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.625rem;
  border-radius: 0.625rem;
  border-radius: 0.625rem;
  background-color: var(--color-gray-700);
}

.cmp-form-options__help-message {
  margin-top: var(--spacing-sm);
}

.cmp-form-options__is-required::after {
  content: ' *';
}

.cmp-form-options__field-description a {
  text-decoration: underline;
}

.cmp-form-options__field-description a:hover {
  color: var(--color-primary-700);
}

.cmp-form-options__field-description.cq-Editable-dom a {
  text-decoration: underline;
}
