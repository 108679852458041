/**
 * Countries options - Component
 */

.cmp-countriesoptions label::after {
  content: ' *';
}

.cmp-countriesoptions__state,
.cmp-countriesoptions__state select {
  display: none;
}

/* validation */
.cmp-required-message {
  order: 3;
}
